import { NewReleases } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import useBreakpoints from '../../helpers/useBreakpoints';
import { selectors } from '../../modules/common';
import AppHeartBeat from './AppHeartBeat';
import messages from './messages';
import { styles } from './styles';

const AppVersionWarning = () => {
  const isVersionWarningDisplayed = useSelector(selectors.isSiteVersionWarningDisplayed);
  return (
    <Box sx={styles.root}>
      {[
        <AppHeartBeat key={'App-heartBeat'} />,
        isVersionWarningDisplayed && <AppVersionNotification key={'app-version-warning'} />,
      ]}
    </Box>
  );
};

function AppVersionNotification() {
  return (
    <Box sx={styles.notificationBar}>
      <Box alignItems={'flex-start'} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
        <NotificationIcon />
        <MaintenanceMessage />
        {/* <NotificationMessage /> */}
        {/* <NotificationButton /> */}
      </Box>
    </Box>
  );
}

function NotificationIcon() {
  return (
    <Box sx={styles.iconStyle}>
      <NewReleases />
    </Box>
  );
}

function NotificationMessage() {
  const { downSm } = useBreakpoints();

  return (
    <>
      <Box
        sx={{
          ...styles.messageStyle,
        }}
      >
        <FormattedMessage {...messages.alertMainMessage} />
        <span>&nbsp;</span>
        <Link
          onClick={() => {
            if (downSm) {
              window.location.reload();
            }
          }}
          sx={styles.messageLink}
        >
          <FormattedMessage {...messages.alertMessageEnding} />
        </Link>
      </Box>
    </>
  );
}

function NotificationButton() {
  return (
    <Box sx={styles.actionStyle}>
      <Button color="inherit" onClick={() => window.location.reload()}>
        <FormattedMessage {...messages.alertRefreshButton} />
      </Button>
    </Box>
  );
}

function MaintenanceMessage() {
  const { downSm } = useBreakpoints();

  return (
    <>
      <Box
        sx={{
          ...styles.messageStyle,
        }}
      >
        {
          'My2N is under scheduled maintenance. Admin management, invoices, service purchases, and billing updates may be temporarily unavailable.'
        }
      </Box>
    </>
  );
}

export default AppVersionWarning;
