/* eslint-disable sort/object-properties */
import companyTypes from '../company/types';
import siteTypes from '../sites/types';
import types from './types';

export const initialState = {
  companySwitcher: {
    data: [],
    didInvalid: false,
    isFetching: false,
  },
  featureFlags: {
    globalFeatures: [],
    isFetching: false,
  },
  gtc: {
    agreements: {
      data: [],
      didInvalid: false,
      isFetching: false,
    },
    detail: {
      data: null,
      didInvalid: false,
      isFetching: false,
      notFound: false,
    },
    list: {
      data: [],
      didInvalid: false,
      isFetching: false,
    },
    optOutText: {
      helperText: null,
      label: null,
    },
  },
  serviceAlert: {
    height: null,
    show: false,
  },
  sidebar: {
    collapse: false,
  },
  siteSwitcher: {
    data: [],
    didInvalid: false,
    isFetching: false,
  },
  showVersionWarning: true,
  version: null,
};

const companyAndSiteSwitcher = {
  [types.COMPANY_SWITCHER_LOAD_DATA_REQUEST]: (state) => ({
    ...state,
    companySwitcher: {
      ...state.companySwitcher,
      isFetching: true,
    },
  }),
  [types.COMPANY_SWITCHER_LOAD_DATA_SUCCESS]: (state, { payload }) => ({
    ...state,
    companySwitcher: {
      ...state.companySwitcher,
      data: payload,
      didInvalid: false,
      isFetching: false,
    },
  }),
  [types.COMPANY_SWITCHER_LOAD_DATA_FAILURE]: (state) => ({
    ...state,
    companySwitcher: initialState.companySwitcher,
  }),
  [siteTypes.EDIT_SITE_FORM_SUCCESS]: (state) => ({
    ...state,
    siteSwitcher: {
      ...state.siteSwitcher,
      didInvalid: true,
    },
  }),
  [types.INVALIDATE_SITE_SWITCHER]: (state) => ({
    ...state,
    siteSwitcher: {
      ...state.siteSwitcher,
      didInvalid: true,
    },
  }),
  [types.INVALIDATE_COMPANY_SWITCHER]: (state) => ({
    ...state,
    companySwitcher: {
      ...state.companySwitcher,
      didInvalid: true,
    },
  }),
};

const siteEffect = {
  [companyTypes.ADD_COMPANY_SUCCESS]: (state) => ({
    ...state,
    companySwitcher: {
      ...state.companySwitcher,
      didInvalid: true,
    },
  }),
  [siteTypes.ADD_SITE_FORM_SUCCESS]: (state) => ({
    ...state,
    companySwitcher: {
      ...state.companySwitcher,
      didInvalid: true,
    },
    siteSwitcher: {
      ...state.siteSwitcher,
      didInvalid: true,
    },
  }),
  [siteTypes.DELETE_SITE_SUCCESS]: (state) => ({
    ...state,
    siteSwitcher: {
      ...state.siteSwitcher,
      didInvalid: true,
    },
  }),
};

const features = {
  [types.LOAD_GLOBAL_FEATURES_REQUEST]: (state) => ({
    ...state,
    featureFlags: {
      ...state.featureFlags,
      isFetching: true,
    },
  }),
  [types.LOAD_GLOBAL_FEATURES_SUCCESS]: (state, { payload }) => ({
    ...state,
    featureFlags: {
      ...state.featureFlags,
      globalFeatures: payload,
      isFetching: false,
    },
  }),
  [types.LOAD_GLOBAL_FEATURES_FAILURE]: (state) => ({
    ...state,
    featureFlags: {
      ...state.featureFlags,
      globalFeatures: [],
      isFetching: false,
    },
  }),
};

const gtc = {
  [types.LOAD_GTC_AGREEMENTS_REQUEST]: (state) => ({
    ...state,
    gtc: {
      ...state.gtc,
      agreements: {
        ...state.gtc.agreements,
        didInvalid: false,
        isFetching: true,
      },
    },
  }),
  [types.LOAD_GTC_AGREEMENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    gtc: {
      ...state.gtc,
      agreements: {
        ...state.gtc.agreements,
        data: payload,
        isFetching: false,
      },
    },
  }),
  [types.LOAD_GTC_AGREEMENTS_FAILURE]: (state) => ({
    ...state,
    gtc: {
      ...state.gtc,
      agreements: {
        ...state.gtc.agreements,
        data: [],
        isFetching: false,
      },
    },
  }),
  [types.LOAD_GTC_DETAIL_REQUEST]: (state) => ({
    ...state,
    gtc: {
      ...state.gtc,
      detail: {
        ...state.gtc.detail,
        didInvalid: false,
        isFetching: true,
        notFound: false,
      },
    },
  }),
  [types.LOAD_GTC_DETAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    gtc: {
      ...state.gtc,
      detail: {
        ...state.gtc.detail,
        data: payload,
        isFetching: false,
      },
    },
  }),
  [types.LOAD_GTC_DETAIL_FAILURE]: (state) => ({
    ...state,
    gtc: {
      ...state.gtc,
      detail: {
        ...state.gtc.detail,
        data: null,
        isFetching: false,
        notFound: true,
      },
    },
  }),
  [types.LOAD_GTC_LIST_REQUEST]: (state) => ({
    ...state,
    gtc: {
      ...state.gtc,
      list: {
        ...state.gtc.list,
        didInvalid: false,
        isFetching: true,
      },
    },
  }),
  [types.LOAD_GTC_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    gtc: {
      ...state.gtc,
      list: {
        ...state.gtc.list,
        data: payload,
        isFetching: false,
      },
    },
  }),
  [types.LOAD_GTC_LIST_FAILURE]: (state) => ({
    ...state,
    gtc: {
      ...state.gtc,
      list: {
        ...state.gtc.list,
        data: [],
        isFetching: false,
      },
    },
  }),
  [types.GET_GTC_OPT_OUT_TEXT_SUCCESS]: (state, { payload }) => ({
    ...state,
    gtc: {
      ...state.gtc,
      optOutText: {
        helperText: payload.helper,
        label: payload.label,
      },
    },
  }),
  [types.GET_GTC_OPT_OUT_TEXT_FAILURE]: (state) => ({
    ...state,
    gtc: {
      ...state.gtc,
      optOutText: initialState.gtc.optOutText,
    },
  }),
};

const reducers = {
  ...companyAndSiteSwitcher,
  ...siteEffect,
  ...features,
  ...gtc,
  [types.COLLAPSE_SIDEBAR]: (state) => ({
    ...state,
    sidebar: {
      ...state.sidebar,
      collapse: !state.sidebar.collapse,
    },
  }),
  [types.SET_SERVICE_ALERT_HEIGHT]: (state, { height }) => ({
    ...state,
    serviceAlert: {
      ...state.serviceAlert,
      height,
    },
  }),
  [types.SET_SERVICE_ALERT_SHOW]: (state, { show }) => ({
    ...state,
    serviceAlert: {
      ...state.serviceAlert,
      show,
    },
  }),
  [types.SITE_VERSION_WARNING_SHOW]: (state) => ({
    ...state,
    showVersionWarning: true,
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
